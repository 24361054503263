*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.link-container {
    margin: 8px 16px;
    position: fixed;
    bottom: 0;
    right: 0;
    color: white
}

.link {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    margin-bottom: 8px;
    cursor: pointer;
}

.link img {
    margin-right: 6px;
}

.hover-underline-animation {
    position: relative;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
